import React from "react";
import event1 from "../../Assets/img/event1.jfif";

const NewsCard = ({ imgUrl, date, heading, summary, url }) => {
  return (
    <div className="latest-news-col">
      <div className="news-updates-itam">
        <div className="news-updates-itam-body">
          <div className="news-img">
            <img src={imgUrl ?? event1} alt="slider" />
            {date && (
              <div className="news-date">
                <span>{date}</span>
              </div>
            )}
          </div>
          <div className="news-body">
            <h3>{heading}</h3>
            <p>{summary}</p>
            <a href={url} title="Read More" className="main-link" tabIndex="0">
              <i className="fas fa-arrow-right"></i> Read More
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsCard;
