import React, { useEffect, useState } from 'react'


const CountDown = ({date}) => {
    const [timeLeft, setTimeLeft] = useState(0)

    useEffect(() => {
        setTimeout(() => {
            setTimeLeft((date.getTime() - Date.now()))
        }, 1000)
    })

    const days = Math.floor(timeLeft / (24 * 60 * 60 * 1000))
    const hours = Math.floor((timeLeft % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000))
    const minutes = Math.floor((timeLeft % (60 * 60 * 1000)) / (60 * 1000))
    const seconds = Math.floor((timeLeft % (60 * 1000)) / (1000))
    return (
        <div id="countdown">
            <ul>
                <li><span id="days">{days}</span>days</li>
                <li><span id="hours">{hours}</span>Hours</li>
                <li><span id="minutes">{minutes}</span>Minutes</li>
                <li><span id="seconds">{seconds}</span>Seconds</li>
            </ul>
        </div>
    )
}

export default CountDown