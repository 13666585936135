import React, { useState } from "react";
import "../../Styles/style.css";

const GalleryImageCard = ({ imgUrl, heading, onClick }) => {
  return (
    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image mb-4">
      <div className="img-wrapper">
        <a>
          <img src={imgUrl} className="img-responsive" />
        </a>
        <div className="img-overlay" onClick={() => onClick(true)}>
          <i className="fa fa-plus-circle" aria-hidden="true"></i>
        </div>
        <div className="gallery-title">
          <h3>{heading}</h3>
        </div>
      </div>
    </div>
  );
};

export default GalleryImageCard;
