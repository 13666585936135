import "../Styles/App.css";
import DefaultLayout from "../Layout/DefaultLayout.jsx";
import OpenRoutes from "../Routes/OpenRoutes.jsx";

function App() {
  return (
    <div className="App">
      <DefaultLayout>
        <OpenRoutes />
      </DefaultLayout>
    </div>
  );
}

export default App;
