import React from "react";
import event1 from "../../Assets/img/event1.jfif";

const UEvenetCard = ({ imgUrl, url, heading, location, date, className }) => {
  return (
    <div className={`col-lg-4 mt-4 ${className}`}>
      <div className="event-2">
        <div className="event-img">
          <img src={imgUrl ?? event1} alt="" />
        </div>
        <div className="event-body">
          <h3>
            <a href={url} className="sub-title-2 d-block">
              {heading}
            </a>
          </h3>
          <div className="recent-event-date">
            <i className="far fa-calendar-alt green-text"></i>{" "}
            <span className="pl-2">{date}</span>{" "}
          </div>
          <div className="recent-event-date">
            <i className="fas fa-map-marker-alt green-text"></i>{" "}
            <span className="pl-2">{location} </span>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UEvenetCard;
