import React, { useState } from "react";
import { Link } from "react-router-dom";
import NewsCard from "../Components/Cards/NewsCard";

import banner from "../Assets/img/banner-2.jpg";
import news1 from "../Assets/img/news1.jpg";
import news2 from "../Assets/img/news2.jpg";
import news3 from "../Assets/img/news3.jfif";
import news4 from "../Assets/img/news4.jfif";
import UEvenetCard from "../Components/Cards/UEvenetCard";
import ButtonSlider from "../Components/Carousel/ButtonSlider";

const News = () => {
  const [apiData, setApiData] = useState({
    news: [
      {
        imgUrl: news1,
        date: "July 16 2023",
        heading:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        summary:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        url: "#",
        location: " New York AK United States ",
      },
      {
        imgUrl: news2,
        date: "July 16 2023",
        heading:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        summary:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        url: "#",
        location: " New York AK United States ",
      },
      {
        imgUrl: news3,
        date: "July 16 2023",
        heading:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        summary:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        url: "#",
        location: " New York AK United States ",
      },
      {
        imgUrl: news4,
        date: "July 16 2023",
        heading:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        summary:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        url: "#",
        location: " New York AK United States ",
      },
      {
        imgUrl: news4,
        date: "July 16 2023",
        heading:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        summary:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        url: "#",
        location: " New York AK United States ",
      },
      {
        imgUrl: news4,
        date: "July 16 2023",
        heading:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        summary:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        url: "#",
        location: " New York AK United States ",
      },
      {
        imgUrl: news4,
        date: "July 16 2023",
        heading:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        summary:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        url: "#",
        location: " New York AK United States ",
      },
      {
        imgUrl: news4,
        date: "July 16 2023",
        heading:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        summary:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        url: "#",
        location: " New York AK United States ",
      },
      {
        imgUrl: news4,
        date: "July 16 2023",
        heading:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        summary:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        url: "#",
        location: " New York AK United States ",
      },
      {
        imgUrl: news4,
        date: "July 16 2023",
        heading:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        summary:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        url: "#",
        location: " New York AK United States ",
      },
      {
        imgUrl: news4,
        date: "July 16 2023",
        heading:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        summary:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        url: "#",
        location: " New York AK United States ",
      },
      {
        imgUrl: news4,
        date: "July 16 2023",
        heading:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        summary:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        url: "#",
        location: " New York AK United States ",
      },
      {
        imgUrl: news4,
        date: "July 16 2023",
        heading:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        summary:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        url: "#",
        location: " New York AK United States ",
      },
    ],
  });
  const [displayRecords, setDisplayRecords] = useState(6);
  return (
    <>
      <section className="inner-banner">
        <div className="inner-banner-img">
          <img src={banner} alt="" />
        </div>
        <div className="banner-overlay">
          <div className="container">
            <h2>News </h2>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  News{" "}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
      <section className="upcoming-event">
        <div className="container">
          <div className="monin-service-title">
            <h3 className="main-title text-center mb-3">
              AIMC's All Latest News
            </h3>
          </div>
          <div className="row">
            {apiData.news.slice(0, displayRecords).map((news) => (
              <div className="col-lg-4 col-sm-6 mt-4" id="event-card">
                <NewsCard
                  imgUrl={news.imgUrl}
                  date={news.date}
                  heading={news.heading}
                  summary={news.summary}
                  url={news.url}
                />
              </div>
            ))}
          </div>

          {displayRecords < apiData.news.length && (
            <div className="mt-5">
              <div className="text-center">
                <button
                  href="#"
                  className="btn green-btn"
                  id="loadMore"
                  onClick={() => setDisplayRecords((prvs) => prvs + 3)}
                >
                  Load More
                </button>
              </div>
            </div>
          )}
        </div>
      </section>
      <section className="latest-news">
        <div className="container">
          <h2 className="main-title mb-3">Latest Events</h2>
          <div className="latest-news-row">
            <ButtonSlider>
              <>
                {apiData.news.slice(0, displayRecords).map((news) => (
                  // <UEvenetCard
                  //     imgUrl={news.imgUrl}
                  //     date={news.date}
                  //     heading={news.heading}
                  //     summary={news.summary}
                  //     url={news.url}
                  // location={news.location}
                  //     className="col-lg-12 mt-4"
                  // />
                  <div className="col-lg-12 mt-4">
                    <div className="event-2">
                      <div className="event-img">
                        <img src={news.imgUrl} alt="" />
                      </div>
                      <div className="event-body">
                        <h3>
                          <a
                            href={news.url}
                            className="sub-title-2 d-block text-truncate"
                          >
                            {news.heading}
                          </a>
                        </h3>
                        <div className="recent-event-date">
                          <i className="far fa-calendar-alt green-text"></i>{" "}
                          <span className="pl-2">{news.date}</span>{" "}
                        </div>
                        <div className="recent-event-date">
                          <i className="fas fa-map-marker-alt green-text"></i>{" "}
                          <span className="pl-2">{news.location}</span>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            </ButtonSlider>
          </div>
        </div>
      </section>
    </>
  );
};

export default News;
