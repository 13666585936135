import React from "react";

const JobCard = ({ imgUrl, position, companyName }) => {
  return (
    <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
      <div class="card text-center border-none team-item-1">
        <div class="job-body">
          <div class="job-img text-center">
            <img src={imgUrl} alt="" />
          </div>
          <h3 class="sub-title-2">{position}</h3>
          <span>{companyName}</span>
          <div class="job-apply-row">
            <div class="job-loction">Delhi</div>
            <a href="job-detail.html" class="btn boreder-btn">
              View More
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobCard;
