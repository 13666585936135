import React, { useState } from "react";
import { Link } from "react-router-dom";
import ContactUsForm from "../Components/Forms/ContactUsForm";
import banner from "../Assets/img/banner-2.jpg";
import w10 from "../Assets/img/icon-w-10.png";
import w9 from "../Assets/img/icon-w-9.png";
import w8 from "../Assets/img/icon-w-8.png";
import phoneIcon from "../Assets/img/phone.svg";
import addressIcon from "../Assets/img/address-icon.svg";
import mailIcon from "../Assets/img/mail.svg";

import MapCard from "../Components/Cards/MapCard";
import ReactVisibilitySensor from "react-visibility-sensor";

const ContactUs = () => {
  const [apiData, setapiData] = useState({
    address: [
      {
        heading: "Kanpur",
        url: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d228624.56107403865!2d80.1735678330011!3d26.44738660230561!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399c4770b127c46f%3A0x1778302a9fbe7b41!2sKanpur%2C%20Uttar%20Pradesh!5e0!3m2!1sen!2sin!4v1725271296336!5m2!1sen!2sin",
        phoneNo: "+91 9810016199",
        email: "mominconfrence@gmail.com",
        address: "H. No 421-A Street No 11 Laxmi Nagar Delhi-110092 ",
      },
      {
        heading: "Delhi",
        url: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d448193.951046296!2d76.76356261822156!3d28.644287349748502!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd5b347eb62d%3A0x37205b715389640!2sDelhi!5e0!3m2!1sen!2sin!4v1725271200489!5m2!1sen!2sin",
        phoneNo: "+91 9810016199",
        email: "mominconfrence@gmail.com",
        address: "H. No 421-A Street No 11 Laxmi Nagar Delhi-110092 ",
      },
      {
        heading: "Kanpur",
        url: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d228624.56107403865!2d80.1735678330011!3d26.44738660230561!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399c4770b127c46f%3A0x1778302a9fbe7b41!2sKanpur%2C%20Uttar%20Pradesh!5e0!3m2!1sen!2sin!4v1725271296336!5m2!1sen!2sin",
        phoneNo: "+91 9810016199",
        email: "mominconfrence@gmail.com",
        address: "H. No 421-A Street No 11 Laxmi Nagar Delhi-110092 ",
      },
    ],
  });
  return (
    <>
      <section className="inner-banner">
        <div className="inner-banner-img">
          <img src={banner} alt="" />
        </div>
        <div className="banner-overlay">
          <div className="container">
            <h2>Contact us</h2>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Contact Us{" "}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
      <section className="contact-us-body">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <h2 className="main-title">Support & General Inquiries</h2>
              <p>
                We endeavour to provide the best possible service and support.
                If you've got a question or a concern, send us an email or give
                us a call and we'll respond as quickly as possible.
              </p>
              <div className="common-form">
                <ContactUsForm />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="contact-info-wrapper text-white">
                <div className="contact-info-title">
                  <h5 className="sub-title-2 text-white">Get Information</h5>
                  <p>
                    Our Contact information Details and Follow us on social
                    media
                  </p>
                </div>
                <div className="contact-info-content">
                  <div className="contact-info-item">
                    <div className="contact-info-inner">
                      <div className="contact-info-thumb">
                        <img src={w10} alt="address" />
                      </div>
                      <div className="contact-info-details">
                        <span className="text-white">Office Address</span>
                        <p>1201 park street, Fifth Avenue</p>
                      </div>
                    </div>
                  </div>
                  <div className="contact-info-item">
                    <div className="contact-info-inner">
                      <div className="contact-info-thumb">
                        <img src={w8} alt="address" />
                      </div>
                      <div className="contact-info-details">
                        <span className="text-white">Phone Number</span>
                        <p>+22698 745 632,02 982 745</p>
                      </div>
                    </div>
                  </div>
                  <div className="contact-info-item">
                    <div className="contact-info-inner">
                      <div className="contact-info-thumb">
                        <img src={w9} alt="address" />
                      </div>
                      <div className="contact-info-details">
                        <span className="text-white">Send Mail</span>
                        <p>adminHafsa@gmil.com</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={`our-office aos-init aos-animate`} data-aos="fade-up">
        <div className="container">
          <h2 className="main-title">
            Our <span>Offices</span>
          </h2>
          <div className="row">
            <div className="col-md-12">
              <div className="our-office-container">
                <div className="our-office-col-1">
                  <h3>Delhi, Global Head Quarters</h3>
                  <ul className="our-office-address">
                    <li>
                      <div className="d-flex align-items-center">
                        <div className="icon-width-1">
                          <span>
                            <img src={mailIcon} alt="" />
                          </span>
                        </div>
                        <div className="icon-width-2">
                          <a href="mailto:mominconfrence@gmail.com">
                            mominconfrence@gmail.com
                          </a>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex align-items-center">
                        <div className="icon-width-1">
                          <span>
                            <img src={phoneIcon} alt="" />
                          </span>
                        </div>
                        <div className="icon-width-2">
                          <a href="tel:+91 9810016199">+91 9810016199 </a>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex align-items-center">
                        <div className="icon-width-1">
                          <span>
                            <img src={addressIcon} alt="" />
                          </span>
                        </div>
                        <div className="icon-width-2">
                          <a href="#">
                            H. No 421-A Street No 11 Laxmi Nagar Delhi-110092{" "}
                          </a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="our-office-col-2">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d448193.951046296!2d76.76356261822156!3d28.644287349748502!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd5b347eb62d%3A0x37205b715389640!2sDelhi!5e0!3m2!1sen!2sin!4v1725271200489!5m2!1sen!2sin"
                    width="600"
                    height="450"
                    style={{ border: "0" }}
                    allowFullScreen
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
            {apiData.address.map((add, index) => (
              <div
                className={`col-lg-4 col-sm-6 aos-init aos-animate`}
                data-aos="fade-left"
              >
                <MapCard
                  key={index}
                  address={add.address}
                  email={add.email}
                  heading={add.heading}
                  phoneNo={add.phoneNo}
                  url={add.url}
                />
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
