import React, { useContext } from "react";
import icon8 from "../Assets/img/icon-8.png";
import icon9 from "../Assets/img/icon-9.png";
import icon10 from "../Assets/img/icon-10.png";
import logoW from "../Assets/img/logo-w.png";
import news1 from "../Assets/img/news-1.jpg";
import news2 from "../Assets/img/news-1.jpg";
import { ApiDataContext } from "../Contexts/ApiData";

const Footer = () => {
  const apiData = useContext(ApiDataContext);

  const FullAddress = apiData?.Response?.CMSPage?.FullAddress;
  const ContactInfo = apiData?.Response?.CMSPage?.ContactInfo;
  return (
    <footer>
      <div className="footer-1">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-sm-4 pl-0 pr-0">
                <div className="footer-top-col">
                  <img src={icon8} alt="" />
                  <p>
                    Phone Number :{" "}
                    <a href="tel:+91 9810016199">{ContactInfo}</a>
                  </p>
                </div>
              </div>
              <div className="col-sm-4 pl-0 pr-0">
                <div className="footer-top-col">
                  <img src={icon9} alt="" />
                  <p>
                    Email :{" "}
                    <a href="mailto:mominconfrence@gmail.com">
                      mominconfrence@gmail.com
                    </a>
                  </p>
                </div>
              </div>
              <div className="col-sm-4 pl-0 pr-0">
                <div className="footer-top-col">
                  <img src={icon10} alt="" />
                  <p>
                    Address :{" "}
                    <a
                      href="https://maps.app.goo.gl/BTiutuE7JRW8ZzMq8"
                      target="_blank"
                    >
                      {" "}
                      {FullAddress}{" "}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-body">
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-sm-6">
                <div className="footer-col">
                  <h2 className="sub-title text-white">ABOUT AIMC</h2>
                  <img src={logoW} alt="" className="w-100 mb-4" />
                  <p>
                    The Momin Conference "saw itself as articulating the
                    interests of ordinary Muslims" as opposed to the "Muslim
                    League, the latter being perceived a party of eite Muslims".
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="footer-col">
                  <h2 className="sub-title text-white">OUR RECENT NEWS</h2>
                  <div className="recent-news-row">
                    <div className="recent-news-img">
                      <img src={news1} alt="" />
                    </div>
                    <div className="recent-news-content">
                      <h3>
                        <a href="#">
                          AIMC 4th National Talent Search 2023 Exam lorem AIMC
                          4th National Talent Search 2023 Exam.
                        </a>
                      </h3>
                      <span>July 23, 2021</span>
                    </div>
                  </div>
                  <div className="recent-news-row">
                    <div className="recent-news-img">
                      <img src={news2} alt="" />
                    </div>
                    <div className="recent-news-content">
                      <h3>
                        <a href="#">
                          AIMC organised a Press Conference lorem AIMC 4th
                          National Talent Search 2023 Exam.
                        </a>
                      </h3>
                      <span>July 23, 2021</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="footer-col">
                  <h2 className="sub-title text-white">OUR NEWSLETTER</h2>
                  <p>
                    AIMC is a nonproﬁt organization supported by community
                    leaders
                  </p>
                  <input
                    type="email"
                    placeholder="Enter Email"
                    className="form-control"
                  />
                  <div className="mt-3">
                    <a href="#" className="btn green-btn">
                      Send Message <i className="fas fa-paper-plane"></i>
                    </a>
                  </div>
                </div>
                <div className="footer-social-icon">
                  <ul className="list-unstyled">
                    <li>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-2">
        <div className="container">
          <p className="mb-0 text-center">
            ©2021 <span className="green-text">AIMC</span> - All Rights Reserved
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
