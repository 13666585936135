import React, { useContext, useEffect, useState } from "react";
import banner from "../Assets/img/banner-2.jpg";
import ButtonSlider from "../Components/Carousel/ButtonSlider";
import SquareImageSlider from "../Components/Carousel/SquareImageSlider";
import NewsCard from "../Components/Cards/NewsCard";
import UserCard from "../Components/Cards/UserCard";
import { Link } from "react-router-dom";
import { ApiDataContext } from "../Contexts/ApiData";

const History = () => {
  const apiData = useContext(ApiDataContext);
  const stateLeaders = apiData?.Response?.StateLeaders;
  const allIndiaLeaders = apiData?.Response?.AllIndiaLeaders;
  const historySlider = apiData?.Response?.HistorySlider;
  const coreLeaders = apiData?.Response?.CoreLeaders;
  const eminentLeaders = apiData?.Response?.EminentLeaders;
  const awards = apiData?.Response?.Awards;
  const historyContent = apiData?.Response?.CMSPage?.HistoryContent;

  return (
    <>
      <section className="inner-banner">
        <div className="inner-banner-img">
          <img src={banner} alt="" />
        </div>
        <div className="banner-overlay">
          <div className="container">
            <h2>Our History</h2>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Our History{" "}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
      <section className="aimc-history">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="float-className">
                <div className="about-img">
                  <div className="about-img-row">
                    <SquareImageSlider images={historySlider} />
                  </div>
                </div>
              </div>
              <h2 className="main-title mb-3">
                As Momin Conference Also Known As Jamaat-Ul-Ansar
              </h2>
              <h3 className="sub-title mb-3">
                That Was Founded In India In 1911
              </h3>
              <div className="text-left">
                {historyContent}
                {/* <p>
                  The Momin Conference "saw itself as articulating the interests
                  of ordinary Muslims" as opposed to the "Muslim League, the
                  latter being perceived a party of eite Muslims". In 1940, the
                  All India Momin Conference passed a resolution in Patna that
                  opposed the partition of India.
                </p>
                <p>
                  It said: “the Partition scheme was not only impracticable and
                  unpatriotic but altogether un-Islamic and unnatural, because
                  the geographical position of the different provinces of India
                  and the intermingled population of the Hindus and Muslims are
                  against the proposal and because the two communities have been
                  living together for centuries, and they have many things in
                  common between them.
                </p> */}
                {/* <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Tempora illum facilis nobis vero placeat, dolores labore
                  dolorum eaque voluptate, aperiam veniam? Vitae quas amet cum
                  sint hic rem consequatur cupiditate!
                </p> */}
                {/* <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Repellendus atque, similique consequatur debitis animi
                  mollitia quibusdam at iusto, aspernatur minima perferendis
                  molestiae aut velit natus, quod nostrum saepe! Veritatis,
                  eligendi!
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="awards">
        <div className="container">
          <h2 className="main-title mb-3">Awards and Recognition</h2>
          <div className="awards-row">
            <ButtonSlider>
              <>
                {awards &&
                  awards?.map((award, index) => (
                    <div className="awards-col">
                      <NewsCard
                        key={award?.AwardId}
                        imgUrl={
                          process.env.REACT_APP_API_URL +
                          "/" +
                          award.AwardImagePath
                        }
                        date={award?.AwardYear}
                        heading={award?.AwardName}
                        summary={award?.AwardContent}
                        location={award?.AwardLocation}
                        // url={news.url}
                      />
                    </div>
                  ))}
              </>
            </ButtonSlider>
          </div>
        </div>
      </section>

      <section className="board-management">
        <div className="container">
          <div className="monin-service-title">
            <h2 className="sub-title text-center mb-3">Eminent Leaders</h2>
            <h3 className="main-title text-center mb-5">
              Our Management Whose Knowledge Is Useful For Others
            </h3>
          </div>
          <div className="row justify-content-center">
            {eminentLeaders &&
              eminentLeaders?.map((leader) => (
                <UserCard
                  key={leader?.LeaderId}
                  name={leader?.LeaderName}
                  summary={leader.LeaderDesignation}
                  imgUrl={
                    process.env.REACT_APP_API_URL + "/" + leader.LeaderImagePath
                  }
                />
              ))}
          </div>
          <div className="board-leaders">
            <h2 className="sub-title text-center mb-3">Core Commitee</h2>
            <div className="row justify-content-center">
              {coreLeaders &&
                coreLeaders?.map((leader) => (
                  <UserCard
                    key={leader?.LeaderId}
                    name={leader?.LeaderName}
                    summary={leader.LeaderDesignation}
                    imgUrl={
                      process.env.REACT_APP_API_URL +
                      "/" +
                      leader.LeaderImagePath
                    }
                  />
                ))}
            </div>
          </div>
          <div className="board-leaders">
            <h2 className="sub-title text-center mb-3">
              All India Working Committee
            </h2>
            <div className="row justify-content-center">
              {allIndiaLeaders &&
                allIndiaLeaders?.map((leader) => (
                  <UserCard
                    key={leader?.LeaderId}
                    name={leader?.LeaderName}
                    summary={leader.LeaderDesignation}
                    imgUrl={
                      process.env.REACT_APP_API_URL +
                      "/" +
                      leader.LeaderImagePath
                    }
                  />
                ))}
            </div>
          </div>
          <div className="board-leaders">
            <h2 className="sub-title text-center mb-3">
              State Presidents of Aimc
            </h2>
            <div className="row justify-content-center">
              {stateLeaders &&
                stateLeaders?.map((leader) => (
                  <UserCard
                    key={leader?.LeaderId}
                    name={leader?.LeaderName}
                    summary={leader.LeaderDesignation}
                    imgUrl={
                      process.env.REACT_APP_API_URL +
                      "/" +
                      leader.LeaderImagePath
                    }
                  />
                ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default History;
