import React, { useContext, useEffect, useState } from "react";
import HomeEvent from "../Components/Cards/EventCard";
import CountDown from "../Components/CountDown";
import UEvenetCard from "../Components/Cards/UEvenetCard";

import news1 from "../Assets/img/news1.jpg";
import icon1 from "../Assets/img/icon-1.png";
import icon2 from "../Assets/img/icon-2.png";
import icon3 from "../Assets/img/icon-3.png";
import icon4 from "../Assets/img/icon-4.png";
import ButtonSlider from "../Components/Carousel/ButtonSlider";
import MainCarousel from "../Components/Carousel/MainCarousel";
import SquareImageSlider from "../Components/Carousel/SquareImageSlider";
import NewsCard from "../Components/Cards/NewsCard";
import banner1 from "../Assets/img/banner-1-1.jpeg";
import { ApiDataContext } from "../Contexts/ApiData";
import Modal from "../Components/Modal";

const Home = () => {
  const [showHistoryModel, setshowHistoryModel] = useState(false);

  const contextApiData = useContext(ApiDataContext);
  const historyImagesPath =
    contextApiData?.Response?.CMSPage?.HistoryImagePaths;
  const carousel = contextApiData?.Response?.BannerList;
  const newsList = contextApiData?.Response?.NewsList;
  const eventList = contextApiData?.Response?.EventList;
  const donationList = contextApiData?.Response?.DonationList;
  const homeHistoryContent =
    contextApiData?.Response?.CMSPage?.HomeHistoryContent;
  const historyContent = contextApiData?.Response?.CMSPage?.HistoryContent;
  const impactTitle1 = contextApiData?.Response?.CMSPage?.ImpactTitle1;
  const impactTitle2 = contextApiData?.Response?.CMSPage?.ImpactTitle2;
  const impactTitle3 = contextApiData?.Response?.CMSPage?.ImpactTitle3;
  const impactTitle4 = contextApiData?.Response?.CMSPage?.ImpactTitle4;

  return (
    <>
      <MainCarousel carousel={carousel} />
      <section className="about-us">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h2 className="sub-title mb-3">Our History</h2>
              <h3 className="main-title mb-3">
                As Momin Conference also known as Jamaat-ul-Ansar
              </h3>
              {/* <h4 className="sub-title-2 mb-3">{historyContent}</h4> */}
              {/* <p className="text-truncate">{historyContent}
              </p> */}
              <p className="para-6-line">{homeHistoryContent}</p>
              <div className="mt-3">
                <button
                  className="btn green-btn"
                  onClick={() => setshowHistoryModel(true)}
                >
                  Read More <i className="fas fa-heart"></i>
                </button>
              </div>
            </div>
            <div className="col-md-6">
              <div className="about-img">
                <div className="about-img-row">
                  <SquareImageSlider images={historyImagesPath} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          showModal={showHistoryModel}
          closeModal={setshowHistoryModel}
          heading={"Our History"}
          summary={historyImagesPath}
        />
      </section>
      <section className="key-point">
        <div className="container">
          <h2 className="main-title text-center mb-4">Our Impact</h2>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="key-point-body">
                <div className="key-point-icon mb-3">
                  <img src={icon1} alt="icon" />
                </div>
                <h2 className="sub-title-2">
                  <a href="#">{impactTitle1}</a>
                </h2>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="key-point-body">
                <div className="key-point-icon mb-3">
                  <img src={icon2} alt="icon" />
                </div>
                <h2 className="sub-title-2">
                  <a href="#">{impactTitle2}</a>
                </h2>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="key-point-body">
                <div className="key-point-icon mb-3">
                  <img src={icon3} alt="icon" />
                </div>
                <h2 className="sub-title-2">
                  <a href="#">{impactTitle3}</a>
                </h2>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="key-point-body">
                <div className="key-point-icon mb-3">
                  <img src={icon4} alt="icon" />
                </div>
                <h2 className="sub-title-2">
                  <a href="#">{impactTitle4}</a>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="monin-service">
        <div className="container">
          <div className="monin-service-title">
            <h2 className="sub-title text-center mb-3">Momin Latest News</h2>
            <h3 className="main-title text-center mb-5">
              Empowering Momin: Tailored News for the Muslim Community
            </h3>
          </div>
          <div className="latest-news-row">
            <ButtonSlider>
              <>
                {newsList &&
                  newsList.map((news, index) => (
                    <NewsCard
                      key={news?.NewsId}
                      imgUrl={
                        process.env.REACT_APP_API_URL +
                        "/" +
                        news?.NewsImagePath
                      }
                      date={news?.ScheduleDate}
                      heading={news?.NewsTitle}
                      summary={news?.NewsContent}
                      // url={news.url}
                    />
                  ))}
              </>
            </ButtonSlider>
          </div>
        </div>
      </section>
      <section className="awards">
        <div className="container">
          <h2 className="main-title mb-3">Donation Programs</h2>
          <div className="awards-row">
            <ButtonSlider>
              <>
                {donationList &&
                  donationList.map((event, index) => (
                    <HomeEvent
                      key={event?.DonationId}
                      imgUrl={
                        process.env.REACT_APP_API_URL +
                        "/" +
                        event?.DonationImagePath
                      }
                      // iconUrl={event.iconUrl}
                      heading={event?.DonationName}
                      summary={event?.DonationContent}
                      url={event?.DonationURL}
                      date={event?.DonationDate}
                    />
                  ))}
              </>
            </ButtonSlider>
          </div>
        </div>
      </section>
      <section className="upcoming-event">
        <div className="container">
          <div className="monin-service-title">
            <h2 className="sub-title text-center mb-3">upcoming event</h2>
            <h3 className="main-title text-center mb-5">
              Radiant Crescent: Illuminating Unity and Diversity -The Glorious
              Gathering of the aimc
            </h3>
          </div>
          <div className="row">
            <div className="col-md-12">
              {eventList && eventList.length && (
                <div className="event-1" key={eventList[0]?.EventId}>
                  <div className="recent-event-img">
                    <img
                      src={
                        eventList[0]?.EventImagePath
                          ? process.env.REACT_APP_API_URL +
                            "/" +
                            eventList[0]?.EventImagePath
                          : news1
                      }
                      alt=""
                    />
                  </div>
                  <div className="recent-event-detail">
                    <h3 className="mb-3">{eventList[0]?.EventTitle}</h3>
                    <div className="recent-event-date">
                      <i className="far fa-calendar-alt green-text"></i>{" "}
                      <span className="pl-2">
                        {eventList[0]?.ScheduleDate.slice(0, 10)}
                      </span>{" "}
                    </div>
                    <div className="recent-event-date">
                      <i className="fas fa-map-marker-alt green-text"></i>{" "}
                      <span className="pl-2">
                        {eventList[0]?.EventLocation}
                      </span>{" "}
                    </div>
                    <CountDown
                      date={new Date(eventList[0]?.EventDateRange.slice(0, 10))}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="col-md-12">
              <div className="row">
                {eventList &&
                  eventList.map(
                    (uEvenet, index) =>
                      index > 0 && (
                        <UEvenetCard
                          key={uEvenet?.EventId}
                          heading={uEvenet?.EventTitle}
                          date={uEvenet?.ScheduleDate?.slice(0, 10)}
                          imgUrl={
                            process.env.REACT_APP_API_URL +
                            "/" +
                            uEvenet?.EventImagePath
                          }
                          location={uEvenet?.EventLocation}
                          url={uEvenet?.url}
                          summary={uEvenet?.EventContent}
                        />
                      )
                  )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
