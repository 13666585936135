import React, { useContext, useState } from "react";
import logo from "../Assets/img/logo.png";
import { Link } from "react-router-dom";
import { ApiDataContext } from "../Contexts/ApiData";

const Header = () => {
  const apiData = useContext(ApiDataContext);

  const FullAddress = apiData?.Response?.CMSPage?.FullAddress;
  const ContactInfo = apiData?.Response?.CMSPage?.ContactInfo;

  return (
    <header className="header-aimc">
      <div>
        <nav className="navbar navbar-expand-xl navbar-light">
          <Link className="navbar-brand mob-logo" to="/">
            <img src={logo} alt="" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded={false}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="navbar-collapse collapse" id="navbarSupportedContent">
            <div className="header-top">
              <div className="container-fluid">
                <div className="header-top-area">
                  <ul className="header-left">
                    <li>
                      <i className="fas fa-phone-alt"></i>{" "}
                      <span>{ContactInfo ?? ""}</span>
                    </li>
                    <li>
                      <i className="fas fa-map-marker-alt"></i>
                      {FullAddress ?? ""}
                    </li>
                  </ul>
                  <ul className="social-icons d-flex">
                    <li>
                      <Link to="news">News</Link>
                    </li>
                    <li>
                      <Link to="events"> Event</Link>
                    </li>
                    <li>
                      <Link to="donation">Donation</Link>
                    </li>
                    <li>
                      <Link to="jobs">Job & Vacancy</Link>
                    </li>
                    <li>
                      <Link to="membership">Join us</Link>
                    </li>
                    {/* <li>
										<Link to="contact-us.html">Contact us</Link>
									</li>  */}
                  </ul>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <div className="header-tow">
                <div className="desktop-logo">
                  <Link className="navbar-brand" to="/">
                    <img src={logo} alt="" />
                  </Link>
                </div>
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link className="nav-link" to="/">
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="history">
                      Our History
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="about">
                      About Us
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="social-responsibility">
                      Social Responsibility
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="gallery">
                      Gallery
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="contactus">
                      Contact us
                    </Link>
                  </li>
                </ul>
                <div className="navbar-icon">
                  <Link className="nav-link navbar-icon-bg" to="#" title="">
                    <i className="fas fa-search" id="search-bar-btn"></i>
                  </Link>
                  <form
                    className="search-bar"
                    id="header-search"
                    action="/Home/Search"
                    method="post"
                  >
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        name="searchTxt"
                        placeholder="Search"
                      />
                      <div className="input-group-append">
                        <button className="btn" type="submit">
                          <i className="fas fa-search"></i>
                        </button>
                      </div>
                    </div>
                    <input
                      name="__RequestVerificationToken"
                      type="hidden"
                      value="CfDJ8IU_NwcdqFxMjmxQM8xgjwFo8vOxx2ly6lKV4JDrMDunfy3hEfCtga-hV5h8xVp7-7oZMPXu7XEP9BBbzef5RieY_SfbzZQParMe3eclyr6qPu_3h69cs2lON3wdTZIyEaberMDdED4OIXQ4Kcg58XA"
                    />
                  </form>
                  <Link className="nav-link navbar-icon-bg" to="login" title="">
                    <i className="fas fa-user"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
