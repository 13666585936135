import React, { useState } from "react";
import banner from "../Assets/img/banner-2.jpg";
import ourHistory from "../Assets/img/our-history.jpg";
import event1 from "../Assets/img/event1.jfif";
import event2 from "../Assets/img/event2.jfif";
import event3 from "../Assets/img/event3.jfif";
import event4 from "../Assets/img/event4.jfif";
import news1 from "../Assets/img/news1.jpg";
import news2 from "../Assets/img/news2.jpg";
import news3 from "../Assets/img/news3.jfif";
import news4 from "../Assets/img/news4.jfif";
import ButtonSlider from "../Components/Carousel/ButtonSlider";
import NewsCard from "../Components/Cards/NewsCard";
import { Link } from "react-router-dom";

const SocialResponsibility = () => {
  const [apiData, setapiData] = useState({
    initiatives: [
      {
        imgUrl: event1,
        heading:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      },
      {
        imgUrl: event2,
        heading:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      },
      {
        imgUrl: event3,
        heading:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      },
      {
        imgUrl: event4,
        heading:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      },
    ],
    news: [
      {
        imgUrl: news1,
        date: "July 16 2023",
        heading:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        summary:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        url: "#",
      },
      {
        imgUrl: news2,
        date: "July 16 2023",
        heading:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        summary:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        url: "#",
      },
      {
        imgUrl: news3,
        date: "July 16 2023",
        heading:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        summary:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        url: "#",
      },
      {
        imgUrl: news4,
        date: "July 16 2023",
        heading:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        summary:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        url: "#",
      },
    ],
  });
  return (
    <>
      <section className="inner-banner">
        <div className="inner-banner-img">
          <img src={banner} alt="" />
        </div>
        <div className="banner-overlay">
          <div className="container">
            <h2>Social Responsibility</h2>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Social Responsibility{" "}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
      <section className="commitment-positive">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="commitment-img">
                <img src={event1} alt="" />
              </div>
              <div>
                <h2 className="main-title mb-3">
                  Commitment to Positive Change
                </h2>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, Lorem
                  Ipsum is simply dummy text of the printing and typesetting
                  industry. Lorem Ipsum has been the industry's standard dummy
                  text ever since the 1500s, when an unknown printer took a
                  galley of type and scrambled it to make a type specimen book.
                  It has survived not only five centuries Lorem Ipsum is simply
                  dummy text of the printing and typesetting industry. Lorem
                  Ipsum has been the industry's standard dummy text ever since
                  the 1500s, when an unknown printer took a galley of type and
                  scrambled it to make a type specimen book. It has survived not
                  only five centuries,
                </p>
                <p>
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit. At
                  explicabo exercitationem illum deleniti temporibus est
                  voluptatibus molestias ipsum ratione fuga ullam, laudantium ab
                  consequuntur dignissimos quisquam quidem officiis fugit
                  laborum.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="key-initiatives">
        <div className="container">
          <h2 className="main-title mb-4">Key Initiatives</h2>
          <p>
            Lorem Ipsum. Progravida nibh vel velit auctor alinean sollicitudin,
            lorem quis bibendum auctor. It has survived not only five centuries
            but also. It has survived not only five centuries but also.go
            sollicitu
          </p>
          <ButtonSlider className="new-key-initiatives">
            <>
              {apiData.initiatives.map((initiative) => (
                <div className="new-key-initiatives-col">
                  <div className="key-initiatives-col">
                    <div className="key-initiatives-img">
                      <img src={initiative.imgUrl} alt="" />
                    </div>
                    <h3>{initiative.heading}</h3>
                  </div>
                </div>
              ))}
            </>
          </ButtonSlider>
        </div>
      </section>

      <section className="monin-service">
        <div className="container">
          <div>
            <h3 className="main-title mb-4">CSR Events</h3>
          </div>
          <div className="latest-news-row">
            <ButtonSlider>
              <>
                {apiData.news.map((news, index) => (
                  <NewsCard
                    key={index}
                    imgUrl={news.imgUrl}
                    date={news.date}
                    heading={news.heading}
                    summary={news.summary}
                    url={news.url}
                  />
                ))}
              </>
            </ButtonSlider>
            {/* <div className="latest-news-col">
					<div className="news-updates-itam">
						<div className="news-updates-itam-body">
							<div className="news-img">
								<img src="img/event-1.png" alt="slider" />
								<div className="news-date">
									<span>July 16 2023</span>
								</div>
							</div>
							<div className="news-body">
								<h3>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h3>
								<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
								<a href="#" title="Read More" className="main-link" tabindex="0"><i className="fas fa-arrow-right"></i> Read More</a>
							</div>
						</div>
					</div>
				</div>
				<div className="latest-news-col">
					<div className="news-updates-itam">
						<div className="news-updates-itam-body">
							<div className="news-img">
								<img src="img/event-2.png" alt="slider" />
								<div className="news-date">
									<span>July 16 2023</span>
								</div>
							</div>
							<div className="news-body">
								<h3>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h3>
								<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
								<a href="#" title="Read More" className="main-link" tabindex="0"><i className="fas fa-arrow-right"></i> Read More</a>
							</div>
						</div>
					</div>
				</div>
				<div className="latest-news-col">
					<div className="news-updates-itam">
						<div className="news-updates-itam-body">
							<div className="news-img">
								<img src="img/event-3.png" alt="slider" />
								<div className="news-date">
									<span>July 16 2023</span>
								</div>
							</div>
							<div className="news-body">
								<h3>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h3>
								<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
								<a href="#" title="Read More" className="main-link" tabindex="0"><i className="fas fa-arrow-right"></i> Read More</a>
							</div>
						</div>
					</div>
				</div>
				<div className="latest-news-col">
					<div className="news-updates-itam">
						<div className="news-updates-itam-body">
							<div className="news-img">
								<img src="img/event-2.png" alt="slider" />
								<div className="news-date">
									<span>July 16 2023</span>
								</div>
							</div>
							<div className="news-body">
								<h3>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h3>
								<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
								<a href="#" title="Read More" className="main-link" tabindex="0"><i className="fas fa-arrow-right"></i> Read More</a>
							</div>
						</div>
					</div>
				</div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default SocialResponsibility;
