import React, { useState } from "react";
import j1 from "../Assets/img/jg1.png";

import JobCard from "../Components/Cards/JobCard";

const Jobs = () => {
  const [apiData, setApiData] = useState({
    jobs: [
      {
        position: "Software Engineer",
        company: "Google",
        location: "Delhi",
        imgUrl: j1,
      },
      {
        position: "Network Engineer",
        company: "Microsoft",
        location: "Mumbai",
        imgUrl: j1,
      },
      {
        position: "Frontend Engineer",
        company: "Apple",
        location: "Bangalore",
        imgUrl: j1,
      },
      {
        position: "Backend Engineer",
        company: "Amazon",
        location: "Delhi",
        imgUrl: j1,
      },
      {
        position: "Software Engineer",
        company: "Google",
        location: "Gurugram",
        imgUrl: j1,
      },
      {
        position: "Software Engineer",
        company: "Google",
        location: "Delhi",
        imgUrl: j1,
      },
      {
        position: "Software Engineer",
        company: "Google",
        location: "Delhi",
        imgUrl: j1,
      },
      {
        position: "Software Engineer",
        company: "Google",
        location: "Delhi",
        imgUrl: j1,
      },
      {
        position: "Software Engineer",
        company: "Google",
        location: "Delhi",
        imgUrl: j1,
      },
      {
        position: "Software Engineer",
        company: "Google",
        location: "Delhi",
        imgUrl: j1,
      },
    ],
  });
  const [pageNumber, setpageNumber] = useState(0)
  const recordPerPage = 6
  const startIndex = pageNumber * recordPerPage
  const numberOfPages = Math.ceil(apiData.jobs.length / recordPerPage)
  return (
    <>
      <section class="inner-banner">
        <div class="inner-banner-img">
          <img src="img/banner-2.jpg" alt="" />
        </div>
        <div class="banner-overlay">
          <div class="container">
            <h2>Job & Vacancy</h2>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="index.html">Home</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Job & Vacancy{" "}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
      <section class="job-search-body">
        <div class="container">
          <h2 class="main-title text-center">
            Explore Thousand Of Jobs With Just Simple Search...
          </h2>
          <div class="job-search">
            <div class="job-search-col-1">
              <div class="input-group">
                <input
                  type="search"
                  class="form-control"
                  placeholder="Job title, keywords or company name"
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <i class="far fa-keyboard"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="job-search-col-2">
              <div class="input-group">
                <input
                  type="search"
                  class="form-control"
                  placeholder="Location"
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <i class="fas fa-map-marker-alt"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="job-search-col-3">
              <button type="submit" class="btn green-btn">
                <i class="fas fa-search"></i>
              </button>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-md-12 mb-3">
              <a href="#" class="btn green-btn job-btn">
                <i class="far fa-envelope"></i> Email me Jobs Like These
              </a>
            </div>
            <div class="col-md-6">
              <h3 class="sub-title">98 Jobs & Vacancies</h3>
            </div>
            <div class="col-md-6">
              <div class="job-filter common-form">
                <span>Sort by:</span>
                <select name="cars" class="custom-select">
                  <option selected>Most Recent</option>
                  <option value="Most Recent-1">Most Recent-1</option>
                  <option value="Most Recent-2">Most Recent-2</option>
                  <option value="Most Recent-3">Most Recent-3</option>
                </select>
              </div>
            </div>
          </div>
          <div class="job-list">
            <div class="row">
              {apiData.jobs.splice(startIndex, recordPerPage).map((job, index) => (
                <JobCard
                  key={index}
                  location={job.location}
                  companyName={job.company}
                  imgUrl={job.imgUrl}
                  position={job.position}
                />
              ))}
            </div>
            <div class="pagination-body">
              <ul>
                <li class="prev">
                  <a href="">
                    <i class="fas fa-chevron-left"></i> Prev
                  </a>
                </li>
                <li>
                  <a href="">1</a>
                </li>
                <li class="active">
                  <a href="">2</a>
                </li>
                <li>
                  <a href="">3</a>
                </li>
                <li>
                  <span class="delimeter">...</span>
                </li>
                <li>
                  <a href="">14</a>
                </li>
                <li class="next">
                  <a href="">
                    Next <i class="fas fa-chevron-right"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Jobs;
