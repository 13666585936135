import React, { useContext, useState } from "react";
import banner from "../Assets/img/banner-2.jpg";
import SquareImageSlider from "../Components/Carousel/SquareImageSlider";

import UserCard from "../Components/Cards/UserCard";
import { Link } from "react-router-dom";
import { ApiDataContext } from "../Contexts/ApiData";

const About = () => {
  const contextApiData = useContext(ApiDataContext);
  const formerLeaders = contextApiData?.Response?.FormerLeaders;
  const presidentPhotoPath =
    contextApiData?.Response?.CMSPage?.PresidentPhotoPath;
  const visionImagePath = contextApiData?.Response?.CMSPage?.VisionImagePath;
  const presidentMessage = contextApiData?.Response?.CMSPage?.PresidentMessage;
  const presidentContent = contextApiData?.Response?.CMSPage?.PresidentContent;
  const visionContent = contextApiData?.Response?.CMSPage?.VisionContent;
  const goalContent = contextApiData?.Response?.CMSPage?.GoalContent;
  return (
    <>
      <section className="inner-banner">
        <div className="inner-banner-img">
          <img src={banner} alt="" />
        </div>
        <div className="banner-overlay">
          <div className="container">
            <h2>About us</h2>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  About Us{" "}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
      <section className="our-vision-goal">
        <div className="container">
          <div className="our-vision">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div>
                  <h3 className="main-title">Our Vision</h3>
                  {/* <ul>
                    <li>
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      </p>{" "}
                    </li>
                    <li>
                      {" "}
                      <p>
                        {" "}
                        Eum ad, quod quaerat odit quia, sapiente dolor molestiae
                        velit mollitia
                      </p>
                    </li>
                    <li>
                      {" "}
                      <p>
                        magnam quam aperiam non sed aspernatur cupiditate
                        explicabo asperiores voluptas fugiat.
                      </p>
                    </li>
                  </ul> */}
                  <p>{visionContent}</p>
                </div>
              </div>
              <div className="col-md-6">
                <SquareImageSlider images={[visionImagePath]} />
              </div>
            </div>
          </div>
          <div className="our-goal">
            <div className="row align-items-center">
              <div className="col-md-6">
                <SquareImageSlider images={[visionImagePath]} />
              </div>
              <div className="col-md-6">
                <div>
                  <h3 className="main-title">Our Goal</h3>
                  {/* <ul>
                    <li>
                      {" "}
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.{" "}
                      </p>
                    </li>
                    <li>
                      {" "}
                      <p>
                        Eum ad, quod quaerat odit quia, sapiente dolor molestiae
                        velit mollitia{" "}
                      </p>
                    </li>
                    <li>
                      {" "}
                      <p>
                        magnam quam aperiam non sed aspernatur cupiditate
                        explicabo asperiores voluptas fugiat.{" "}
                      </p>
                    </li>
                  </ul> */}
                  <p>{goalContent}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="president-message">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="president-img">
                <img
                  src={process.env.REACT_APP_API_URL + "/" + presidentPhotoPath}
                  alt=""
                />
              </div>
              <h2 className="main-title mb-3">{presidentMessage}</h2>
              {/* <p></p> */}
              <p>{presidentContent}</p>
              {/* <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Libero
                quam porro fugiat quisquam ut, fugit quas nostrum labore
                inventore nihil, veritatis est perspiciatis laudantium,
                cupiditate quis commodi quasi praesentium magnam.
              </p>
              <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Libero
                quam porro fugiat quisquam ut, fugit quas nostrum labore
                inventore nihil, veritatis est perspiciatis laudantium,
                cupiditate quis commodi quasi praesentium magnam.
              </p>
              <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Libero
                quam porro fugiat quisquam ut, fugit quas nostrum labore
                inventore nihil, veritatis est perspiciatis laudantium,
                cupiditate quis commodi quasi praesentium magnam.
              </p>
              <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Libero
                quam porro fugiat quisquam ut, fugit quas nostrum labore
                inventore nihil, veritatis est perspiciatis laudantium,
                cupiditate quis commodi quasi praesentium magnam.
              </p> */}
            </div>
          </div>
        </div>
      </section>
      <section className="board-management the-presidents">
        <div className="container">
          <div className="monin-service-title">
            <h2 className="sub-title text-center mb-3">Former Presidents</h2>
            <h3 className="main-title text-center mb-5">
              {" "}
              Presidents members Of All India Momin Conferance
            </h3>
          </div>
          <div className="row justify-content-center">
            {formerLeaders &&
              formerLeaders?.map((leader) => (
                <UserCard
                  key={leader?.LeaderId}
                  name={leader?.LeaderName}
                  summary={leader?.LeaderName}
                  imgUrl={
                    process.env.REACT_APP_API_URL +
                    "/" +
                    leader?.LeaderImagePath
                  }
                  readMore
                />
              ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
