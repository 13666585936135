import React from "react";
import event1 from "../../Assets/img/event1.jfif";

const Slide = ({ imgUrl, url, heading, summary, showModal }) => {
  return (
    <div className="slide">
      <div className="container">
        <div className="banner-row">
          <div
            className="banner-col-img animated"
            data-animation-in="zoomInImage"
            style={{ opacity: 0 }}
          >
            <img src={imgUrl ?? event1} alt="" />
          </div>
          <div className="slide__content">
            <div className="slide__content--headings">
              <h2
                className="animated"
                data-animation-in="fadeInUp"
                style={{ opacity: 0 }}
              >
                {heading}
              </h2>
              <p
                className="animated"
                data-animation-in="fadeInUp"
                data-delay-in="0.3"
                style={{ opacity: 0 }}
              >
                {summary}
              </p>
              <div
                data-animation-in="fadeInUp"
                data-delay-in="0.6"
                className="slide-btn mt-3"
                style={{ opacity: 0 }}
              >
                <button
                  type="button"
                  className="btn green-btn"
                  data-toggle="modal"
                  data-target="#myModal"
                  onClick={() => showModal(true)}
                >
                  Read More <i className="fas fa-heart"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slide;
