import React, { useState } from "react";

import banner from "../Assets/img/banner-2.jpg";
import ourHistory from "../Assets/img/our-history.jpg";
import event1 from "../Assets/img/event1.jfif";
import event2 from "../Assets/img/event2.jfif";
import event3 from "../Assets/img/event3.jfif";
import event4 from "../Assets/img/event4.jfif";
import d1 from "../Assets/img/d-1.png";
import d2 from "../Assets/img/d-2.png";
import d3 from "../Assets/img/d-3.png";
import d4 from "../Assets/img/d-4.png";
import news1 from "../Assets/img/news1.jpg";
import news2 from "../Assets/img/news2.jpg";
import news3 from "../Assets/img/news3.jfif";
import news4 from "../Assets/img/news4.jfif";
import icon1 from "../Assets/img/icon-1.png";
import icon2 from "../Assets/img/icon-2.png";
import icon3 from "../Assets/img/icon-3.png";
import icon4 from "../Assets/img/icon-4.png";
import ButtonSlider from "../Components/Carousel/ButtonSlider";
import MainCarousel from "../Components/Carousel/MainCarousel";
import SquareImageSlider from "../Components/Carousel/SquareImageSlider";
import NewsCard from "../Components/Cards/NewsCard";
import banner1 from "../Assets/img/banner-1-1.jpeg";
import UEvenetCard from "../Components/Cards/UEvenetCard";
import { Link } from "react-router-dom";

const Events = () => {
  const [displayRecords, setDisplayRecords] = useState(6);
  const [apiData, setApiData] = useState({
    events: [
      {
        imgUrl: event1,
        iconUrl: d1,
        heading: "Caring Hands",
        summary:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        url: "#",
      },
      {
        imgUrl: event2,
        iconUrl: d2,
        heading: "Generous Hearts",
        summary:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        url: "#",
      },
      {
        imgUrl: event3,
        iconUrl: d3,
        heading: "Gentle Giving",
        summary:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        url: "#",
      },
      {
        imgUrl: event4,
        iconUrl: d4,
        heading: "Child Aware",
        summary:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        url: "#",
      },
    ],
    upcomingEvents: [
      {
        heading: "AIMC Resource Center event",
        date: "December 24, 2021",
        location: "New York AK United States",
        imgUrl: news1,
      },
      {
        heading: "Resume Assist for students event...",
        date: "December 24, 2021",
        location: "New York AK United States",
        imgUrl: news2,
      },
      {
        heading: "AIMC Economic Development Plan",
        date: "December 24, 2021",
        location: "New York AK United States",
        imgUrl: news3,
      },
      {
        heading: "team Meeting in Delhi",
        date: "December 24, 2021",
        location: "New York AK United States",
        imgUrl: news4,
      },
      {
        heading: "AIMC Resource Center event",
        date: "December 24, 2021",
        location: "New York AK United States",
        imgUrl: news1,
      },
      {
        heading: "Resume Assist for students event...",
        date: "December 24, 2021",
        location: "New York AK United States",
        imgUrl: news2,
      },
      {
        heading: "AIMC Economic Development Plan",
        date: "December 24, 2021",
        location: "New York AK United States",
        imgUrl: news3,
      },
      {
        heading: "AIMC Resource Center event",
        date: "December 24, 2021",
        location: "New York AK United States",
        imgUrl: news1,
      },
      {
        heading: "Resume Assist for students event...",
        date: "December 24, 2021",
        location: "New York AK United States",
        imgUrl: news2,
      },
      {
        heading: "AIMC Economic Development Plan",
        date: "December 24, 2021",
        location: "New York AK United States",
        imgUrl: news3,
      },
    ],
    news: [
      {
        imgUrl: news1,
        date: "July 16 2023",
        heading:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        summary:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        url: "#",
      },
      {
        imgUrl: news2,
        date: "July 16 2023",
        heading:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        summary:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        url: "#",
      },
      {
        imgUrl: news3,
        date: "July 16 2023",
        heading:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        summary:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        url: "#",
      },
      {
        imgUrl: news4,
        date: "July 16 2023",
        heading:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        summary:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        url: "#",
      },
    ],
  });
  return (
    <>
      <section className="inner-banner">
        <div className="inner-banner-img">
          <img src={banner} alt="" />
        </div>
        <div className="banner-overlay">
          <div className="container">
            <h2>Event </h2>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link href="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {" "}
                  Event{" "}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
      <section className="upcoming-event">
        <div className="container">
          <div className="monin-service-title">
            <h3 className="main-title text-center mb-3">AIMC's All Events</h3>
          </div>
          <div className="row">
            {apiData.upcomingEvents
              .slice(0, displayRecords)
              .map((event, index) => (
                <UEvenetCard
                  key={index}
                  imgUrl={event.imgUrl}
                  date={event.date}
                  heading={event.heading}
                  summary={event.summary}
                  url={event.url}
                  location={event.location}
                />
              ))}
          </div>

          {displayRecords < apiData.upcomingEvents.length && (
            <div className="mt-5">
              <div className="text-center">
                <button
                  className="btn green-btn"
                  id="loadMore"
                  onClick={() => setDisplayRecords((prvs) => prvs + 3)}
                >
                  Load More
                </button>
              </div>
            </div>
          )}
        </div>
      </section>
      <section className="latest-news">
        <div className="container">
          <h2 className="main-title mb-3">Latest News</h2>
          <div className="latest-news-row">
            {/* <div className="latest-news-col">
              <div className="news-updates-itam">
                <div className="news-updates-itam-body">
                  <div className="news-img">
                    <img src="img/event-1.png" alt="slider" />
                    <div className="news-date">
                      <span>July 16 2023</span>
                    </div>
                  </div>
                  <div className="news-body">
                    <h3>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </h3>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </p>
                    <a
                      href="#"
                      title="Read More"
                      className="main-link"
                      tabindex="0"
                    >
                      <i className="fas fa-arrow-right"></i> Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="latest-news-col">
              <div className="news-updates-itam">
                <div className="news-updates-itam-body">
                  <div className="news-img">
                    <img src="img/event-2.png" alt="slider" />
                    <div className="news-date">
                      <span>July 16 2023</span>
                    </div>
                  </div>
                  <div className="news-body">
                    <h3>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </h3>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </p>
                    <a
                      href="#"
                      title="Read More"
                      className="main-link"
                      tabindex="0"
                    >
                      <i className="fas fa-arrow-right"></i> Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="latest-news-col">
              <div className="news-updates-itam">
                <div className="news-updates-itam-body">
                  <div className="news-img">
                    <img src="img/event-3.png" alt="slider" />
                    <div className="news-date">
                      <span>July 16 2023</span>
                    </div>
                  </div>
                  <div className="news-body">
                    <h3>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </h3>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </p>
                    <a
                      href="#"
                      title="Read More"
                      className="main-link"
                      tabindex="0"
                    >
                      <i className="fas fa-arrow-right"></i> Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="latest-news-col">
              <div className="news-updates-itam">
                <div className="news-updates-itam-body">
                  <div className="news-img">
                    <img src="img/event-2.png" alt="slider" />
                    <div className="news-date">
                      <span>July 16 2023</span>
                    </div>
                  </div>
                  <div className="news-body">
                    <h3>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </h3>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </p>
                    <a
                      href="#"
                      title="Read More"
                      className="main-link"
                      tabindex="0"
                    >
                      <i className="fas fa-arrow-right"></i> Read More
                    </a>
                  </div>
                </div>
              </div>
            </div> */}
            <ButtonSlider>
              <>
                {apiData.news.map((news, index) => (
                  <NewsCard
                    key={index}
                    imgUrl={news.imgUrl}
                    date={news.date}
                    heading={news.heading}
                    summary={news.summary}
                    url={news.url}
                  />
                ))}
              </>
            </ButtonSlider>
          </div>
        </div>
      </section>
    </>
  );
};

export default Events;
