import React from "react";
import Slider from "react-slick";
import event1 from "../../Assets/img/event1.jfif";

const SquareImageSlider = ({ images }) => {
  const settings = {
    adaptiveHeight: true,
    infinite: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <Slider {...settings}>
      {images &&
        images.map((img, index) => (
          <div key={index} className="about-img-col">
            <img
              src={img ? process.env.REACT_APP_API_URL + "/" + img : event1}
              alt="About-us"
            />
          </div>
        ))}
    </Slider>
  );
};

export default SquareImageSlider;
