import React, { useEffect, useState } from "react";
import Header from "../Components/Header.jsx";
import Footer from "../Components/Footer.jsx";
// import "bootstrap/dist/css/bootstrap.min.css";
import "../Styles/all.min.css";
import "../Styles/bootstrap.min.css";
import "../Styles/slick.css";
import "../Styles/slick-theme.css";
import "../Styles/animate.min.css";
import "../Styles/aos.css";
import "../Styles/owl.carousel.min.css";
import "../Styles/style.css";
import "../Styles/responsive.css";
import Modal from "../Components/Modal.jsx";
import { axiosInstance } from "../Utils/AxiosRequest.js";
import { ApiDataContext } from "../Contexts/ApiData.js";
import { useLocation } from "react-router-dom";
import { API_PATHS, URL_PATH } from "../Utils/Constants/constants.js";

const DefaultLayout = ({ children }) => {
  const [apiData, setApiData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { pathname } = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      let apiPath;
      switch (pathname) {
        case URL_PATH.home:
          apiPath = API_PATHS.home;
          break;
        case URL_PATH.history:
          apiPath = API_PATHS.history;
          break;
        case URL_PATH.about:
          apiPath = API_PATHS.about;
          break;
        default:
          apiPath = API_PATHS.home;
      }
      console.log(apiPath);

      const response = await axiosInstance.get(apiPath);
      if (response.status == 200) {
        setApiData(response.data);
      }
    };
    try {
      setIsLoading(true);
      fetchData();
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }, [pathname]);

  // if (isLoading) {
  //   console.log(isLoading);

  //   return <p>Loading...</p>;
  // }
  // if (!apiData.Response) {
  //   console.log(isLoading);

  //   return <p>Loading...</p>;
  // }
  return (
    <ApiDataContext.Provider value={{ ...apiData, isLoading }}>
      <Header />
      {children}
      <Footer />
    </ApiDataContext.Provider>
  );
};

export default DefaultLayout;
