import React, { useState } from "react";
import about from "../Assets/img/about.png";
import banner from "../Assets/img/banner-2.jpg";
import event1 from "../Assets/img/event1.jfif";
import event2 from "../Assets/img/event2.jfif";
import event3 from "../Assets/img/event3.jfif";
import event4 from "../Assets/img/event4.jfif";
import GalleryImageCard from "../Components/Cards/GalleryImageCard";
import { Link } from "react-router-dom";

const Gallery = () => {
  const [apiData, setapiData] = useState({
    images: [
      {
        url: about,
        heading: "AIMC’s 4th National Convention 2024",
      },
      {
        url: event1,
        heading: "AMP Lower Parel (Mumbai) Job Fair",
      },
      {
        url: event2,
        heading: "Skill Development Lectures",
      },
      {
        url: event3,
        heading: "Launch of AMP NTS 2023 - Ahmedabad",
      },
      {
        url: about,
        heading: "AIMC 4th NTS 2023 - India's Biggest Talent Search ",
      },
      {
        url: event4,
        heading: "4th National Talent Search in Jaipur",
      },
      {
        url: event1,
        heading: "AMP Lower Parel (Mumbai) Job Fair",
      },
      {
        url: event2,
        heading: "Skill Development Lectures",
      },
    ],
  });
  const [showImage, setShowImage] = useState({
    show: false,
    imgIndex: 0,
  });

  return (
    <>
      <section className="inner-banner">
        <div className="inner-banner-img">
          <img src={banner} alt="" />
        </div>
        <div className="banner-overlay">
          <div className="container">
            <h2>Gallery</h2>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Gallery{" "}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
      <section className="gallery-body" id="gallery">
        <div className="container">
          <div id="image-gallery">
            <div className="row">
              {apiData.images.map((image, index) => (
                <GalleryImageCard
                  key={index}
                  heading={image.heading}
                  imgUrl={image.url}
                  onClick={() =>
                    setShowImage({
                      show: true,
                      imgIndex: index,
                    })
                  }
                />
              ))}
            </div>
          </div>
        </div>

        <div
          className={`${
            showImage.show ? "d-flex opacity-100" : "opacity-0 d-none"
          }`}
          id="overlay"
          onClick={() => setShowImage((prvs) => ({ ...prvs, show: false }))}
        >
          {showImage.imgIndex > 0 && (
            <div
              role="button"
              id="prevButton"
              onClick={(e) => {
                e.stopPropagation();
                setShowImage((prvs) => ({
                  ...prvs,
                  imgIndex: prvs.imgIndex - 1,
                }));
              }}
            >
              <i className="fa fa-chevron-left"></i>
            </div>
          )}
          <img
            className={`${showImage.show ? "opacity-100" : "opacity-0"}`}
            src={apiData.images[showImage.imgIndex].url}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
          {showImage.imgIndex < apiData.images.length - 1 && (
            <div
              role="button"
              id="nextButton"
              onClick={(e) => {
                e.stopPropagation();
                setShowImage((prvs) => ({
                  ...prvs,
                  imgIndex: prvs.imgIndex + 1,
                }));
              }}
            >
              <i className="fa fa-chevron-right"></i>
            </div>
          )}
          <div role="button" id="exitButton">
            <i className="fa fa-times"></i>
          </div>
        </div>
      </section>
    </>
  );
};

export default Gallery;
