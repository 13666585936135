import React from "react";
import phoneIcon from "../../Assets/img/phone.svg";
import addressIcon from "../../Assets/img/address-icon.svg";
import mailIcon from "../../Assets/img/mail.svg";

const MapCard = ({ heading, phoneNo, email, address, url }) => {
  return (
    <div className="our-office-container-card">
      <div className="our-office-card-body">
        <h3>{heading}</h3>
        <div className="address-map">
          <iframe
            src={url}
            width="600"
            height="450"
            style={{ border: "0" }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <ul className="our-office-address-list">
          <li>
            <div className="d-flex align-items-center">
              <div className="icon-width-1">
                <span>
                  <img src={mailIcon} alt="" />
                </span>
              </div>
              <div className="icon-width-2">
                <a href={`mailto:${email}`}>{email}</a>
              </div>
            </div>
          </li>
          <li>
            <div className="d-flex align-items-center">
              <div className="icon-width-1">
                <span>
                  <img src={phoneIcon} alt="" />
                </span>
              </div>
              <div className="icon-width-2">
                <a href={`tel:+91 ${phoneNo}`}>{phoneNo}</a>
              </div>
            </div>
          </li>
          <li>
            <div className="d-flex align-items-center">
              <div className="icon-width-1">
                <span>
                  <img src={addressIcon} alt="" />
                </span>
              </div>
              <div className="icon-width-2">
                <a href="#">{address}</a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MapCard;
