import React, { useState } from "react";
import Modal from "../Modal";
import event1 from "../../Assets/img/event1.jfif";

const UserCard = ({ imgUrl, name, summary, description, readMore }) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <div className="col-xl-3 col-lg-4 col-sm-6 col-12">
      <div className="card text-center border-none team-item-1">
        <div className="lab-inner">
          <div className="lab-thumb">
            <img
              src={imgUrl ?? event1}
              className="card-img-top"
              alt="product"
            />
          </div>
          <div className="lab-content">
            <a href="#">
              <h6 className="card-title mb-0">{name}</h6>
            </a>
            <p className="card-text mb-3">{summary}</p>
            {readMore && (
              <div className="social-share">
                <a
                  data-toggle="modal"
                  data-target="#myModal-1"
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowModal(true);
                  }}
                >
                  Read More
                </a>
              </div>
            )}
            {/* <div className="social-share">
                        <a href="#" className="m-1"><i className="fab fa-facebook-f"></i></a>
                        <a href="#" className="m-1"><i className="fab fa-twitter"></i></a>
                        <a href="#" className="m-1"><i className="fab fa-linkedin-in"></i></a>
                        <a href="#" className="m-1"><i className="fab fa-instagram"></i></a>
                        <a href="#" className="m-1"><i className="fab fa-google-plus-g"></i></a>
                        </div> */}
          </div>
        </div>
      </div>
      <Modal
        showModal={showModal}
        closeModal={setShowModal}
        heading={name}
        summary={description}
        imgUrl={imgUrl}
      />
    </div>
  );
};

export default UserCard;
