import React from "react";

const Modal = ({ showModal, closeModal, heading, summary, imgUrl }) => {
  return (
    <div
      className={`modal fade ${showModal ? "show" : ""}`}
      style={showModal ? { display: "block" } : {}}
      id="myModal"
      onClick={() => closeModal(false)}
    >
      <div
        className="modal-dialog modal-lg"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title sub-title-2">{heading}</h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => closeModal(false)}
            >
              &times;
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              {imgUrl && (
                <div className="col-md-4">
                  <div className="founder-img">
                    <img src={imgUrl} alt="" />
                  </div>
                </div>
              )}
              <div className={imgUrl ? "col-md-8" : "col-md-12"}>
                <div className="founder-detail">
                  <p>{summary}</p>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Ipsam, animi at minima hic velit iste. Inventore quod magnam
                    facilis corrupti explicabo, a ut dicta tenetur. Impedit
                    blanditiis ducimus ipsum accusantium.
                  </p>
                  <p>
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                    Totam culpa ab, minima fugit, maiores vel amet perspiciatis,
                    minus modi et optio ea nihil odit consequuntur quas quis
                    sint! Corporis, harum.
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Ipsum iure possimus enim nisi autem quisquam cumque
                    repudiandae ab dolores cum velit provident, quis, debitis,
                    assumenda magnam recusandae a pariatur dolorem?
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
