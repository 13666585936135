import React, { useState } from "react";

import banner from "../Assets/img/banner-2.jpg";
import ourHistory from "../Assets/img/our-history.jpg";
import event1 from "../Assets/img/event1.jfif";
import event2 from "../Assets/img/event2.jfif";
import event3 from "../Assets/img/event3.jfif";
import event4 from "../Assets/img/event4.jfif";
import d1 from "../Assets/img/d-1.png";
import d2 from "../Assets/img/d-2.png";
import d3 from "../Assets/img/d-3.png";
import d4 from "../Assets/img/d-4.png";
import news1 from "../Assets/img/news1.jpg";
import news2 from "../Assets/img/news2.jpg";
import news3 from "../Assets/img/news3.jfif";
import news4 from "../Assets/img/news4.jfif";
import donate from "../Assets/img/donate.png";
import icon1 from "../Assets/img/icon-1.png";
import icon2 from "../Assets/img/icon-2.png";
import icon3 from "../Assets/img/icon-3.png";
import icon4 from "../Assets/img/icon-4.png";
import ButtonSlider from "../Components/Carousel/ButtonSlider";
import MainCarousel from "../Components/Carousel/MainCarousel";
import SquareImageSlider from "../Components/Carousel/SquareImageSlider";
import NewsCard from "../Components/Cards/NewsCard";
import banner1 from "../Assets/img/banner-1-1.jpeg";
import UEvenetCard from "../Components/Cards/UEvenetCard";
import { Link } from "react-router-dom";
import DonationForm from "../Components/Forms/DonationForm";
const Donation = () => {
  const [apiData, setApiData] = useState({
    events: [
      {
        imgUrl: event1,
        iconUrl: d1,
        heading: "Caring Hands",
        summary:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        url: "#",
      },
      {
        imgUrl: event2,
        iconUrl: d2,
        heading: "Generous Hearts",
        summary:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        url: "#",
      },
      {
        imgUrl: event3,
        iconUrl: d3,
        heading: "Gentle Giving",
        summary:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        url: "#",
      },
      {
        imgUrl: event4,
        iconUrl: d4,
        heading: "Child Aware",
        summary:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        url: "#",
      },
    ],
    upcomingEvents: [
      {
        heading: "AIMC Resource Center event",
        date: "December 24, 2021",
        location: "New York AK United States",
        imgUrl: news1,
      },
      {
        heading: "Resume Assist for students event...",
        date: "December 24, 2021",
        location: "New York AK United States",
        imgUrl: news2,
      },
      {
        heading: "AIMC Economic Development Plan",
        date: "December 24, 2021",
        location: "New York AK United States",
        imgUrl: news3,
      },
      {
        heading: "team Meeting in Delhi",
        date: "December 24, 2021",
        location: "New York AK United States",
        imgUrl: news4,
      },
      {
        heading: "AIMC Resource Center event",
        date: "December 24, 2021",
        location: "New York AK United States",
        imgUrl: news1,
      },
      {
        heading: "Resume Assist for students event...",
        date: "December 24, 2021",
        location: "New York AK United States",
        imgUrl: news2,
      },
      {
        heading: "AIMC Economic Development Plan",
        date: "December 24, 2021",
        location: "New York AK United States",
        imgUrl: news3,
      },
      {
        heading: "AIMC Resource Center event",
        date: "December 24, 2021",
        location: "New York AK United States",
        imgUrl: news1,
      },
      {
        heading: "Resume Assist for students event...",
        date: "December 24, 2021",
        location: "New York AK United States",
        imgUrl: news2,
      },
      {
        heading: "AIMC Economic Development Plan",
        date: "December 24, 2021",
        location: "New York AK United States",
        imgUrl: news3,
      },
    ],
    news: [
      {
        imgUrl: news1,
        date: "July 16 2023",
        heading:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        summary:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        url: "#",
      },
      {
        imgUrl: news2,
        date: "July 16 2023",
        heading:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        summary:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        url: "#",
      },
      {
        imgUrl: news3,
        date: "July 16 2023",
        heading:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        summary:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        url: "#",
      },
      {
        imgUrl: news4,
        date: "July 16 2023",
        heading:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        summary:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        url: "#",
      },
    ],
  });
  return (
    <>
      <section className="inner-banner">
        <div className="inner-banner-img">
          <img src={banner} alt="" />
        </div>
        <div className="banner-overlay">
          <div className="container">
            <h2>Donation</h2>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Donation{" "}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
      <section className="aimc-history">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="float-className">
                <div className="about-img">
                  <img src={donate} alt="About-us" />
                </div>
              </div>
              <h2 className="main-title mb-3">Make A Donation</h2>
              <h3 className="sub-title mb-3">
                Those who spend in charity will be richly rewarded
              </h3>
              <div>
                <p>
                  In the last few years, with the invaluable support from our
                  existing members and our well wishers we were able execute
                  many projects under AIMC.
                </p>
                <p>
                  In our endeavour to do more for the society we need your
                  financial support without which it is not possible to execute
                  projects smoothly. We have many social initiatives for which
                  we need funds so that maximum people can benefit from the
                  same. We appeal to you to join hands with us and thus help us
                  to build a strong society.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="latest-news">
        <div className="container">
          <h2 className="main-title mb-3">Donation program</h2>
          <div className="latest-news-row">
            {/* <div className="col-lg-12 mt-4">
              <div className="event-2">
                <div className="event-img">
                  <img src="img/event-2.png" alt="" />
                </div>
                <div className="event-body">
                  <h3>
                    <a href="#" className="sub-title-2 d-block">
                      Resume Assist for students event...
                    </a>
                  </h3>
                  <div className="recent-event-date">
                    <i className="far fa-calendar-alt green-text"></i>{" "}
                    <span className="pl-2"> December 24,2021</span>{" "}
                  </div>
                  <div className="recent-event-date">
                    <i className="fas fa-map-marker-alt green-text"></i>{" "}
                    <span className="pl-2"> New York AK United States </span>{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 mt-4">
              <div className="event-2">
                <div className="event-img">
                  <img src="img/event-1.png" alt="" />
                </div>
                <div className="event-body">
                  <h3>
                    <a href="#" className="sub-title-2 d-block">
                      Resume Assist for students event...
                    </a>
                  </h3>
                  <div className="recent-event-date">
                    <i className="far fa-calendar-alt green-text"></i>{" "}
                    <span className="pl-2"> December 24,2021</span>{" "}
                  </div>
                  <div className="recent-event-date">
                    <i className="fas fa-map-marker-alt green-text"></i>{" "}
                    <span className="pl-2"> New York AK United States </span>{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 mt-4">
              <div className="event-2">
                <div className="event-img">
                  <img src="img/event-2.png" alt="" />
                </div>
                <div className="event-body">
                  <h3>
                    <a href="#" className="sub-title-2 d-block">
                      Resume Assist for students event...
                    </a>
                  </h3>
                  <div className="recent-event-date">
                    <i className="far fa-calendar-alt green-text"></i>{" "}
                    <span className="pl-2"> December 24,2021</span>{" "}
                  </div>
                  <div className="recent-event-date">
                    <i className="fas fa-map-marker-alt green-text"></i>{" "}
                    <span className="pl-2"> New York AK United States </span>{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 mt-4">
              <div className="event-2">
                <div className="event-img">
                  <img src="img/event-3.png" alt="" />
                </div>
                <div className="event-body">
                  <h3>
                    <a href="#" className="sub-title-2 d-block">
                      Resume Assist for students event...
                    </a>
                  </h3>
                  <div className="recent-event-date">
                    <i className="far fa-calendar-alt green-text"></i>{" "}
                    <span className="pl-2"> December 24,2021</span>{" "}
                  </div>
                  <div className="recent-event-date">
                    <i className="fas fa-map-marker-alt green-text"></i>{" "}
                    <span className="pl-2"> New York AK United States </span>{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 mt-4">
              <div className="event-2">
                <div className="event-img">
                  <img src="img/event-2.png" alt="" />
                </div>
                <div className="event-body">
                  <h3>
                    <a href="#" className="sub-title-2 d-block">
                      Resume Assist for students event...
                    </a>
                  </h3>
                  <div className="recent-event-date">
                    <i className="far fa-calendar-alt green-text"></i>{" "}
                    <span className="pl-2"> December 24,2021</span>{" "}
                  </div>
                  <div className="recent-event-date">
                    <i className="fas fa-map-marker-alt green-text"></i>{" "}
                    <span className="pl-2"> New York AK United States </span>{" "}
                  </div>
                </div>
              </div>
            </div> */}
            <ButtonSlider>
              <>
                {apiData.upcomingEvents.map((event, index) => (
                  <UEvenetCard
                    key={index}
                    imgUrl={event.imgUrl}
                    date={event.date}
                    heading={event.heading}
                    summary={event.summary}
                    url={event.url}
                    location={event.location}
                    className="col-lg-12 mt-4"
                  />
                ))}
              </>
            </ButtonSlider>
          </div>
        </div>
      </section>
      <section className="donation-form">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="donation-form-detail">
                <h2 className="sub-title-2 mb-3">
                  Support All India Momin Conference
                </h2>
                <p>
                  The All India Momin Conference (AIMC) has been working for
                  more than 16 years in the field of Education Support,
                  Employment Assistance, and Economic Empowerment for the
                  Community, Society, and Country. Many thanks for your
                  contribution and support during the last 16 years.{" "}
                </p>
                <p>
                  'AIMC Angel campaign’ is an annual Fund-Raising activity
                  carried out to generate funds for administrative,
                  project-related and operational expenses, which will result in
                  providing Education, Employment and Empowerment to the
                  underprivileged in the Community.{" "}
                </p>
                <p>
                  We request you to inspire more & more people - your family,
                  friends & close contacts, to contribute to the AIMC Angel Fund
                  and thus help in uplifting the Community.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="common-form donation-form-bg">
                <DonationForm />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Donation;
