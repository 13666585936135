import React from "react";
import event1 from "../../Assets/img/event1.jfif";

const EventCard = ({ imgUrl, iconUrl, heading, summary, url }) => {
  return (
    <div className="awards-col">
      <div className="news-updates-itam">
        <div className="news-updates-itam-body">
          <div className="news-img">
            <img src={imgUrl ?? event1} alt="slider" />
          </div>
          <div className="news-body award-bg">
            <div className="d-flex align-items-center">
              {iconUrl && (
                <div className="donation-icon">
                  <img src={iconUrl} alt="" />
                </div>
              )}
              <h3>{heading}</h3>
            </div>
            <p>{summary}</p>
            <a href={url} title="Read More" className="main-link" tabIndex="0">
              <i className="fas fa-arrow-right"></i> Read More
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCard;
